:root {
  ---preloader-transition: opacity 0 cubic-bezier(0.075, 0.82, 0.165, 1);
}

.content--loading {
  opacity: 0;
  transition: var(---preloader-transition);
  transition-duration: 800ms;

  img {
    opacity: 0;
    transition: opacity 1s cubic-bezier(0.13, 0.02, 0.54, 0.75);
  }

  &.content--loaded {
    opacity: 1;

    img {
      opacity: 1;
    }
  }
}

.show--preloader {
  opacity: 1;
  transition: var(---preloader-transition);

  &.hide-preloader-content {
    opacity: 0;
  }
}
