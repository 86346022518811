.arrow-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--primary-green);
  font-weight: 600;

  path {
    stroke: var(--arrow-link-color, #0b8376);
  }
}
