.product-ad {
  --side-padding: 2rem;
  --top-padding: 3rem;

  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--top-padding) var(--side-padding) 0;
  position: relative;
  overflow: hidden;

  border-radius: 0px 0px 1.8rem 1.8rem;
  border-right: 1px solid var(--primary-purple-200, #ede8fb);
  border-bottom: 1px solid var(--primary-purple-200, #ede8fb);
  border-left: 1px solid var(--primary-purple-200, #ede8fb);
  background: var(--primary-purple-200, #ede8fb);

  .hifa {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-left: 0;
    translate: 0 0;
  }

  &::before {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    background: var(--primary-purple-100, #755ae2);
    right: 0;
    top: 0;
  }

  &__header {
    color: var(--grey-600, #646464);
    font-size: 1.3rem;
    font-weight: 400;

    .ad-title {
      color: var(--grey-800, #333);
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.4;
    }

    .ad-text {
      margin-block: 1rem 2rem;
    }

    .arrow-link {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  &__illustration {
    margin-top: auto;
    max-height: 18rem;
    object-fit: contain;
    translate: var(--side-padding) 0;
  }

  @media only screen and (max-width: 992px) {
    border-radius: 1.2rem;
    border: 1px solid var(--primary-100, #e5e0f4);

    &::before {
      display: none;
    }
  }
}

.feather-ad {
  border-right: 1px solid var(--primary-50, #f1f9f8);
  border-bottom: 1px solid var(--primary-50, #f1f9f8);
  border-left: 1px solid var(--primary-50, #f1f9f8);
  background: var(--primary-20, #f2fffa);

  &::before {
    background: var(--primary-600, #0b8376);
  }

  .product-ad__illustration {
    margin-left: auto;
  }

  @media only screen and (max-width: 992px) {
    border: 1px solid var(--primary-100, #e0f1f0);
  }
}
