.footer-mid {
  color: var(--accent-grey-800);
  padding: 64px 0;

  & > * + * {
    margin-top: 64px;
  }

  .footer-mid--show-sm {
    display: none;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 60px 0;
  }

  &__row {
    display: flex;

    h4 {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 24px;
    }

    &--list {
      & > * + * {
        margin-top: 24px;
      }
    }
  }

  &__legal {
    display: grid;
    align-items: center;
    gap: 40px;
    font-size: 1.5rem;
    line-height: 1.8;
    grid-template-columns: 150px 1fr;
    // color: var(--primary-deep-green);
  }

  .footer--bardcode {
    border-radius: 8px;
    border: 1px solid #eee;
    background: #f7f8f7;
    padding: 12px;
  }

  @media only screen and (max-width: 1100px) {
    .footer-mid--show-sm {
      display: block;
    }

    .footer-mid--show-lg {
      display: none;
    }
    &__wrapper {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  @media only screen and (max-width: 992px) {
    &__wrapper {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &__legal {
      display: grid;
      align-items: center;
      justify-content: start;
      gap: 40px;
      font-size: 1.6rem;
      line-height: 1.8;
      grid-template-columns: 1fr;
      color: var(--primary-deep-green);

      .footer--bardcode {
        max-width: 150px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    &__wrapper {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__legal {
      font-size: 1.5rem;
    }
  }
}
