:root {
  --mobile-gutter-spacing: 32px;
}
.header__mobile_menu {
  display: none;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px var(--mobile-gutter-spacing);
    border-bottom: 1px solid var(--grey-100, #eee);
  }

  &__dropdown {
    padding: 20px var(--mobile-gutter-spacing);

    &--trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 20px 0;
      font-weight: 600;
      cursor: pointer;
    }

    &__content {
      color: var(--grey-700, #515151);
      text-transform: capitalize;

      .dropdown__link {
        display: flex;
        gap: 16px;
        width: 100%;
        padding: 10px 20px;
        align-items: center;
        padding-left: 0;

        &__icon {
          max-width: 32px;
          height: 32px;
          width: 100%;
          background-color: #e0f1f0;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }

  &__content {
    background-color: #fff;
    position: fixed;
    inset: 0;
    overflow-y: scroll;

    .menu-view-area {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      height: 100vh;
      padding-bottom: 60px;
      scroll-padding-bottom: 30px;

      .header__mobile_menu__dropdown {
        margin-bottom: 2rem;
      }
    }

    .header-announcement {
      display: none;

      padding: 20px var(--mobile-gutter-spacing);
      margin-top: auto;

      &__content {
        border-radius: 12px;
        border: 1px solid var(--primary-100, #e0f1f0);
        background: var(--primary-20, #f2fffa);
        padding: 2rem;
        padding-bottom: 0;
        height: 370px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        gap: 1.2rem;

        p {
          font-size: 1.5rem;
        }

        h5 {
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 600;
        }

        .arrow-link {
          font-size: 1.45rem;
        }
      }

      @media only screen and (max-width: 576px) {
        display: block;

        .product-ad {
          height: 320px;
        }
      }

      img {
        padding-top: 1rem;
        max-height: 20rem;
        object-fit: contain;
        translate: 10% 0;
        align-self: flex-end;
        margin-top: auto;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    display: block;
  }
}
