.header {
  --bg-opacity: 0.7;

  height: var(--headerHeight, 80px);
  color: var(--accent-grey-800);
  // position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid var(--primary-100, #e0f1f0);
  backdrop-filter: blur(200px);

  .offset-2 {
    margin-block: 2px -2px;
  }

  &.header--dark {
    border-bottom: 0;

    .header__mobile_menu {
      path {
        stroke: #fff;
      }
    }

    .header__navbar__item--trigger path {
      stroke: #fff;
    }

    a:not(.arrow-link) {
      color: inherit;
    }

    .header__right {
      button {
        color: #000;
      }
    }
  }

  &--logo svg {
    height: 35px;
    width: 70px;
  }

  &.header--scroll {
    background-color: rgba($color: #fff, $alpha: var(--bg-opacity));
    backdrop-filter: blur(8px);
    border-bottom: 0;
    box-shadow: rgba(28, 33, 39, 0.08) 0px 1.0566px 42.57px;

    &.header--dark {
      background-color: rgba(0, 0, 0, var(--bg-opacity));
    }
  }

  .header--main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    a {
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--primary-green);
    }
  }

  &__navbar {
    display: flex;
    width: 100%;
    max-width: 380px;
    gap: 16px;
    justify-content: space-between;
    position: relative;
    align-items: center;

    &__item {
      &--trigger {
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: 600;

        svg {
          margin-top: 4px;
          height: 23px;
          width: 23px;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    &__navbar,
    &__right {
      display: none;
    }
  }
}

@keyframes icon-animate {
  0% {
    scale: 1;
  }
  50% {
    scale: 0.9;
    rotate: 10deg;
  }
  100% {
    scale: 1.1;
    rotate: -10deg;
  }
}
