.footer-base {
  border-top: 1px solid #eaecf0;
  padding: 10px;
  padding-block: 32px 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  svg {
    max-height: 40px;
    width: auto;
  }

  &__text {
    color: #666666;

    span {
      color: var(--primary-green);
    }
  }

  @media only screen and (max-width: 756px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
  }
}
