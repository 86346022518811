.headerDropDown {
  position: absolute;
  width: 90vw;
  right: 0;
  left: 0;
  max-width: 900px;
  transform: translateX(-10%);
  z-index: 999;

  &--wrapper {
    display: flex;
    border-radius: 12px;
    gap: 16px;
    border-bottom: 1px solid var(--grey-200, #e3e3e3);
    background: #fff;
    box-shadow: 0px 0px 50px 6px rgba(0, 0, 0, 0.07);
    margin-top: 30px;
  }

  &--info_container {
    padding-bottom: 20px;
    padding-right: 24px;
    width: 100%;
    max-width: 300px;

    .content--image2 {
      max-width: 210px;
      translate: 40px;
    }

    &--content {
      position: relative;
      background-color: var(--primary-green-20);
      height: 100%;
      padding-top: 20px;
      padding-inline: 16px;
      overflow: hidden;
      color: var(--accent-grey-800);
      font-size: 1.2rem;
      margin-top: 1px;

      h5 {
        color: var(--accent-grey-800);
        font-weight: 600;
        font-size: 1.55rem;
        margin-bottom: 12px;
      }

      p {
        margin-bottom: 20px;
      }

      a {
        font-size: 14px;
        color: var(--primary-green);
        font-weight: 600;
      }

      .info_container-cta {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      img {
        position: absolute;
        bottom: 0;
        left: 10px;
      }

      .content--image1 {
        max-height: 200px;
      }

      .content--image2 {
        left: 50px;
      }

      &::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 3px;
        background-color: var(--primary-green);
        content: "";
      }
    }
  }

  &--container {
    width: 100%;
    padding: 40px;
    padding-right: 0;
    max-width: 600px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 1150px) {
    max-width: 800px;
  }

  &__row > * + * {
    display: block;
    margin-top: 24px;
    padding: 12px 0;
  }

  &--card {
    display: flex;
    align-items: start;
    gap: 16px;
    font-size: 1.4rem;

    h5 {
      color: var(--accent-grey-800);
      white-space: nowrap;
    }

    &:hover {
      .card--content p {
        color: rgba(0, 0, 0, 0.6);
      }
      .icon--wrapper {
        transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        svg {
          animation: icon-animate 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
      }
    }

    .icon--wrapper {
      max-width: 50px;
      height: 50px;
      width: 100%;
      background-color: #e0f1f0;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .card--content {
      max-width: calc(100% - 50px);
      width: 100%;
      h5 {
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 4px;
      }

      p {
        color: var(--accent-grey-500);
      }
    }
  }
}
