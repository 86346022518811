@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_extra_light_italic-webfont.woff2')
			format('woff2'),
		url('./fonts/br_firma_extra_light_italic-webfont.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_extra_light-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_extra_light-webfont.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_light_italic-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_light_italic-webfont.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_light-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_light-webfont.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_medium_italic-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_medium_italic-webfont.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_medium-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_medium-webfont.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_regular_italic-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_regular_italic-webfont.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_regular-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_regular-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_semibold_italic-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_semibold_italic-webfont.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_semibold-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_semibold-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_thin_italic-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_thin_italic-webfont.woff') format('woff');
	font-weight: 100;
	font-style: italic;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_thin-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_thin-webfont.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_black_italic-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_black_italic-webfont.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_black-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_black-webfont.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_bold_italic-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_bold_italic-webfont.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: 'br_firma';
	src: url('./fonts/br_firma_bold-webfont.woff2') format('woff2'),
		url('./fonts/br_firma_bold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
