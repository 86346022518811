.download__app {
  display: flex;
  gap: 12px;

  img {
    max-height: 45px;
    transition: all 500ms cubic-bezier(0.39, 0.575, 0.565, 1);
    // width: 100%;

    &:hover {
      transition: all 800ms cubic-bezier(0.39, 0.575, 0.565, 1);
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 999px) {
  }

  @media only screen and (max-width: 576px) {
    img {
      height: 40px;
      width: auto;
    }

    & > * {
      &:last-of-type {
        svg {
          margin-right: auto;
        }
      }
      &:first-of-type {
        svg {
          margin-left: auto;
        }
      }
    }
  }
}
