.nfp {
  position: fixed;
  inset: 0;
  height: 100vh;
  z-index: 2;
  width: 100%;
  background: var(--landing-page-gradient, linear-gradient(138deg, #f0fff9 15.22%, #fffaf0 92.42%));

  &-content {
    position: relative;
    height: 100%;
    padding-top: calc(var(--headerHeight) * 2);
  }

  &__illustration {
    position: absolute;
    bottom: 0;
    width: 100%;

    img {
      max-height: 450px;
      margin: 0 auto;
    }

    @media only screen and (max-width: 576px) {
      img {
        max-height: 380px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;

    .nfp-header {
      font-size: 6rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.4;
    }

    p {
      color: var(--grey-500, #8b8b8b);
      font-weight: 500;
      max-width: 50ch;
      font-size: 1.8rem;
    }

    button {
      margin-top: 2rem;
    }

    @media only screen and (max-width: 576px) {
      gap: 1.6rem;

      .nfp-header {
        font-size: 4rem;
      }

      p {
        font-size: 1.6rem;
      }
    }
  }

  &__banner {
    position: absolute;
    inset: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
  }
}
