.footer-header {
  &__top {
    background-color: #010e0e;
    color: #fff;
    font-size: 1.8rem;
    height: 560px;
    overflow: hidden;
    position: relative;

    p {
      color: #e3e3e3;
      opacity: 0.99;
    }

    &__spirals {
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
      right: 0;
      left: 0;
      bottom: 0;
      height: 200px;
    }

    .image--wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        position: absolute;
        bottom: 0;
        // transform: translateY(220px);
        right: 0;
      }
    }

    .footer-header--app--wrap {
      padding-top: 32px;
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      z-index: 1;
      position: relative;
      height: 100%;
      gap: 40px;

      &--text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .footer-header--content {
          max-width: 550px;
          margin-top: 30px;

          & > * + * {
            margin-top: 20px;
          }
        }
      }
    }

    @media only screen and (max-width: 1100px) {
      height: 500px;

      .footer-header--content {
        margin-block: 80px;
      }
    }
  }

  @media only screen and (max-width: 1050px) {
    .footer-header--content {
      p {
        font-size: 1.5rem;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .footer-header--app--wrap {
      padding-top: 10px;
    }
  }
  @media only screen and (max-width: 756px) {
    &--content {
      display: flex;
      flex-direction: column;
      margin: auto;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .footer-header--content {
      margin-block: 80px 0;
    }

    &__top {
      height: auto;

      &__content {
        grid-template-columns: unset;
      }

      .image--wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;

        img {
          position: unset;
          max-height: 400px;
          transform: translateY(30px);
          height: auto;
        }
      }
    }
  }
}

.footer-header-title {
  font-size: 3.2rem;
  font-weight: 600;

  @media only screen and (max-width: 1100px) {
    font-size: 2.8rem;
  }

  @media only screen and (max-width: 1050px) {
    font-size: 2.4rem;
  }
}
