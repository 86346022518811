@import "fonts";
@tailwind base;
@tailwind components;
@tailwind utilities;

// Root colors
:root {
  // base starts here ----
  --base-white: #ffffff;
  --base-black: #020202;
  --base-background-white: #f9f9f9;
  --base-background-black: #676767;

  // border color start here ----
  --border-green: #0b8376;
  --border-grey-dark: #676767;
  --border-grey-light: #cccccc;
  --border-light: #f7f8f7;

  // primary start here ------
  --primary-green: #0b8376;
  --primary-deep-green: #014345;
  --primary-blue: #476885;
  --primary-orange: #ea872d;
  --primary-purple: #755ae2;
  --primary-black: #020202;

  // accent starts here -------
  --accent-grey-800: #333;
  --accent-grey-500: #8b8b8b;

  --primary-green-20: #f2fffa;

  --headerHeight: 80px;

  --grey600: #646464;

  --grey-700: #515151;

  --landing-page-gradient: linear-gradient(156deg, #f0fff9 0%, #fffaf0 72.92%);

  --gradients-testimonials: linear-gradient(91deg, #f5fffb 13.12%, #fefef0 100%);

  --hero-height-600: 600px;
  --hero-height-750: 750px;

  --grey-50: var(var(--border-grey-light));

  --grid-column: minmax(0, 1fr);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 58% !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  @media only screen and (max-width: 75em) {
    font-size: 58% !important;
  }
}

body {
  font-size: 1.6rem;
  font-family: "br_firma", sans-serif !important;
  font-weight: 400;
  position: relative;
  // width: 100vw;
  overscroll-behavior: none;
  overflow-y: scroll;
  overflow-x: hidden;
  color: var(--accent-grey-800);

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html,
body {
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  font: unset;
}
#root,
#__next {
  isolation: isolate;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.container-wrap {
  max-width: 90%;
  margin: 0 auto;
}

button {
  background-color: transparent;
  border: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.w-full {
  width: 100%;
}
.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.text-purple {
  color: var(--primary-purple);
}
.text-primary-green {
  color: var(--primary-green);
}

.text-gray800,
.text-grey800 {
  color: var(--accent-grey-800);
}

.section-title {
  font-size: 3.8rem;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -1px;
}

.page_hero_title {
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -2.5px;
}

.accent--title {
  color: var(--primary-purple, #755ae2);
  text-align: center;
  font-size: 1.6rem;
}

.text-grey600,
.text-gray600 {
  color: var(--grey600);
}

.section {
  margin-block: 90px;
}
.py90 {
  padding-block: 100px;
}

.mb20 {
  margin-bottom: 20px;
}
.mt20 {
  margin-top: 20px;
}

.mh600 {
  min-height: var(--hero-height-600);
}

.hero-gradient {
  background: var(--landing-page-gradient);
}

.mfs__card--blue {
  --mfs-card-border-color: #b9defe;
  --mfs-card-bg: linear-gradient(221deg, #f8fcff 0%, #e9f5ff 100%);
  --gridArea: 40%;

  h4 {
    color: #476885;
  }
}

.mfs__card--purple {
  --mfs-card-border-color: #dbd6fc;
  --mfs-card-bg: linear-gradient(223deg, #ede8fb 0%, #fafaff 100%);
  --gridArea: 60%;

  h4 {
    color: #755ae2;
  }
}

.mfs__card--grey {
  --mfs-card-border-color: #e3e3e3;
  --mfs-card-bg: linear-gradient(221deg, #f8f9f8 0%, #f3f4f3 100%);
}

.mfs__card--green {
  --mfs-card-border-color: #e0f1f0;
  --mfs-card-bg: linear-gradient(156deg, #f8fffc 18.63%, #fffaf0 72.92%);
}

.mfs__card--deep_blue {
  --mfs-card-border-color: #e3e3e3;
  --mfs-card-bg: linear-gradient(135deg, #e9f5ff 12.75%, rgba(237, 232, 251, 0.38) 100%);
}

.landing-gradient {
  background: var(--landing-page-gradient);
}
.grey--card {
  border: 1px solid var(--grey-200, #e3e3e3);
  background: var(--gradients-grey, linear-gradient(221deg, #f8f9f8 0%, #f3f4f3 100%));
}

.purple--card {
  border: 1px solid var(--primary-purple-200, #ede8fb);
  background: var(--bento-purple-solid, linear-gradient(224deg, #d1c6f4 0%, #ede8fb 99.94%));
}

.text-orange {
  color: #e27715;
}
.birds__footer {
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  // transform: translate(-50%, -50%);
  width: 100%;

  display: flex;
  align-items: end;
  justify-content: end;
}

.youtube__embed {
  position: absolute;
  inset: 0;
}

.house__banner {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 430px;
  background-size: cover;
  background-position: center bottom;
}

.pear__bg {
  background-size: 23%;
  background-position: center;
}

.fade_to_white {
  background: linear-gradient(184deg, #f0fff9 0%, #fffaf0 71.75%, #fff 91.67%);

  & + * {
    margin-top: -20px;
  }

  & > section {
    background: unset;
  }
}

.full-body-gradient {
  background: linear-gradient(180deg, #f0fffa 2.37%, #fefbf1 33.68%, #fff 80.94%);

  & > * {
    background: transparent;
  }
}

.close-modal-button {
  outline: none;
  border: none;
}

@media only screen and (min-width: 576px) {
  .container-wrap {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1280px) {
  .container-wrap {
    max-width: 1150px;
  }
}

.step-wrap-scroll {
  height: 600vh;
  transition: all 1s linear;
  transform: perspective(1000px);

  .spacer {
    padding: 100vh;
  }

  &.unset-padding {
    padding: 0;
  }

  & > div {
    position: sticky;
    inset: 0;
    top: 5rem;
  }

  @media only screen and (max-width: 992px) {
    height: 200vh;

    & > div {
      top: 3rem;
    }
  }
}

@media only screen and (max-width: 992px) {
  .section-title {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 756px) {
  .section-title {
    font-size: 2.89rem;
  }

  .page_hero_title {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 576px) {
  .section-title {
    font-size: 2.4rem;
  }

  .page_hero_title {
    font-size: 3.2rem;
    line-height: 1.4;
  }

  .py90 {
    padding-block: 40px;
  }
}
.progress-bar-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0.3rem;
  height: 100%;
  z-index: 1;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.parallax_wrapper {
  height: 400vh;
  inset: 0;
  scroll-behavior: smooth;

  &__target {
    position: sticky;
    inset: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    scroll-snap-align: center;
    scroll-behavior: smooth;

    & > * {
      width: 100%;
      position: absolute;
      right: 0;
      left: 0;
    }
  }

  @media only screen and (max-width: 576px) {
    height: 600vh;
    &__target {
      inset: unset;
      top: 0;
    }
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: var(--line-clamp, 2);
}

.line-clamp-2 {
  --line-clamp: 2;
}

.hu.hv.hw.hx.hy {
  display: none;
}
